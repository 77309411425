import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import SocialIcons from './elements/SocialIcons';
import { client, setInnerHtml } from '../helpers/contentful';
import { pageTransition } from '../helpers/animation';
import showHeader from '../helpers/header';

export default () => {
  const descriptionSectionRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    client
      .getEntries({ content_type: 'biography', limit: 1 }).then(response => {
        setInnerHtml(
          descriptionSectionRef.current,
          response.items[0].fields.description
        );
        setLoaded(true);
        showHeader();
      });
  }, []);

  return (
    <motion.div
      className="about-wrapper"
      initial={{ opacity: 0 }}
      animate={loaded ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="contents"
        initial={false}
        animate={loaded ? 'in' : 'initial'}
        exit="out"
        variants={pageTransition.slideContentFromBottom.pageVariants}
        transition={pageTransition.slideContentFromBottom.transition}
      >
        <div className="title-section">
          <motion.h1
            initial="initial"
            animate="animate"
          >
            Yume Yamada
          </motion.h1>
          <p>Freelance Artist based in Nagoya, Japan</p>
          <SocialIcons />
        </div>
        <div className="description" ref={descriptionSectionRef} />
        <div className="credits">
          <div className="credit-small-title">
            <p>CREDITS</p>
          </div>
          <div className="links">
            <p>
              <a
                href="https://poly.pizza/m/5mO_DgR5eRX"
                target="_blank"
                rel="noreferrer"
              >
                Hands
              </a>
              {' '}
              by&nbsp;
              <a
                href="https://poly.pizza/u/Michael Fuchs"
                target="_blank"
                rel="noreferrer"
              >
                Michael Fuchs
              </a>
              &nbsp;
              [
              <a
                href="https://creativecommons.org/licenses/by/3.0/"
                target="_blank"
                rel="noreferrer"
              >
                CC-BY
              </a>
              ]
              &nbsp;
              via Poly Pizza
            </p>
            <p>
              <a href="https://poly.pizza/m/iMNqRzPwwe" target="_blank" rel="noreferrer">Chair</a>
              &nbsp;
              by
              &nbsp;
              <a
                href="https://poly.pizza/u/Quaternius"
                target="_blank"
                rel="noreferrer"
              >
                Quaternius
              </a>
            </p>
            <p>
              Website by
              &nbsp;
              <a
                href="https://www.taiseiyamada.com"
                target="_blank"
                rel="noreferrer"
              >
                Taisei Yamada
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
