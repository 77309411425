import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavLinks = ({ mobile, setOpen }) => (
  <ul>
    {Object.entries({ about: '/about', gallery: '/gallery' }).map(navLink => (
      <li key={navLink[0]}>
        <Link to={navLink[1]} onClick={() => (mobile ? setOpen(false) : '')}>
          {navLink[0].toUpperCase()}
        </Link>
      </li>
    ))}
  </ul>
);

export default NavLinks;

NavLinks.propTypes = { mobile: PropTypes.bool, setOpen: PropTypes.func };
NavLinks.defaultProps = { mobile: false, setOpen: () => {} };
