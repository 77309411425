import { useState, useEffect } from 'react';
import { client } from '../../helpers/contentful';
import ContentfulContext from '../context/ContentfulContext';

// eslint-disable-next-line react/prop-types
const ContentfulProvider = ({ children }) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    client.getEntries({ content_type: 'work', order: '-fields.year' })
      .then(response => {
        const works = response.items.map(item => item.fields);
        setRecords(works);
      })
      .catch(console.error);
  }, []);

  return (
    <ContentfulContext.Provider value={records}>
      {children}
    </ContentfulContext.Provider>
  );
};

export default ContentfulProvider;
