import { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import imagesLoaded from 'imagesloaded';
import { Link, useLocation } from 'react-router-dom';
import { pageTransition } from '../helpers/animation';
import showHeader from '../helpers/header';
import ContentfulContext from './context/ContentfulContext';

const Gallery = () => {
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [activeFilterButton, setActiveFilterButton] = useState('All');
  const allWorks = useContext(ContentfulContext);

  const location = useLocation();

  const filterByCategory = (works, category) => works.filter(work => work.category === category);

  const filterWorks = (works, categoryParam) => {
    switch (categoryParam) {
      case 'art':
        setFilteredWorks(filterByCategory(works, 'Art'));
        setActiveFilterButton('Art');

        break;
      case 'apparel':
        setFilteredWorks(filterByCategory(works, 'Apparel'));
        setActiveFilterButton('Apparel');

        break;
      default:
        setFilteredWorks(works);
        setActiveFilterButton('All');

        break;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (filteredWorks.length === 0) {
      setFilteredWorks(allWorks, params.get('category'));
    } else {
      filterWorks(allWorks, params.get('category'));
    }
  }, [allWorks, location]);

  useEffect(() => {
    let totalImagesLoaded = 0;

    filteredWorks
      .map(work => document.querySelector(`.thumbnail[data-key="${work.slug}"]`))
      .forEach(item => {
        imagesLoaded(item, { background: true }, () => {
          totalImagesLoaded += 1;
          if (totalImagesLoaded === filteredWorks.length) {
            showHeader();
            setLoaded(true);
          }
        });
      });
  }, [filteredWorks]);

  return (
    <motion.div
      initial={false}
      animate={loaded ? 'in' : 'initial'}
      exit="out"
      variants={pageTransition.slideContentFromLeft.pageVariants}
      transition={pageTransition.slideContentFromLeft.transition}
      className="transition-container"
    >
      <div className="gallery-wrapper">
        <div className="title-wrapper">
          <h1>GALLERY</h1>
          <div className="filter-buttons">
            { ['All', 'Art', 'Apparel'].map(category => (
              <Link
                to={`/gallery${category === 'All' ? '' : `?category=${category.toLowerCase()}`}`}
                key={category}
                className={`filter-button ${activeFilterButton === category ? 'active' : ''}`}
              >
                <p>{category}</p>
              </Link>
            ))}
          </div>
        </div>
        <div className="works-wrapper">
          {filteredWorks.map(work => (
            <Link to={`/gallery/${work.slug}`} className="work-card" key={work.slug}>
              <div
                style={{ backgroundImage: `url(${work.thumbnail.fields.file.url})` }}
                className="thumbnail"
                data-key={work.slug}
              />
              <p>{work.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Gallery;
