/* eslint-disable react/no-unknown-property */
import { useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import Scene from './Scene';

export default () => {
  const cameraData = useMemo(() => ({
    fov: 45,
    near: 0.05,
    far: 900,
    position: [32, 0, 15]
  }), []);

  return (
    <div className="top">
      <div className="loading-screen">
        <div className="percentage">
          <p>0%</p>
        </div>
        <div className="text">
          <p>Loading</p>
        </div>
      </div>
      <Canvas
        flat
        camera={{
          fov: cameraData.fov,
          near: cameraData.near,
          far: cameraData.far,
          position: cameraData.position
        }}
        gl={{
          antialias: true,
          toneMapping: THREE.LinearToneMapping,
          pixelRatio: Math.min(window.devicePixelRatio, 2)
        }}
      >
        <color args={['#007984']} attach="background" />
        <Scene />
      </Canvas>
    </div>
  );
};
