import { useState } from 'react';
import SocialIcons from '../elements/SocialIcons';
import Logo from './Logo';
import NavLinks from './NavLinks';

export default () => {
  const [open, setOpen] = useState(false);

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 500) setOpen(false);
  });

  return (
    <nav className="mobile-navbar">
      <Logo mobile open setOpen={setOpen} />
      <button
        className={`hamburger hamburger--spin ${open ? 'is-active' : ''}`}
        type="button"
        onClick={() => setOpen(!open)}
        onKeyUp={() => setOpen(!open)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <div className={`hamburger-menu ${open ? 'open' : ''}`}>
        <div className={`contents ${open ? ' open' : ''}`}>
          <NavLinks mobile setOpen={setOpen} />
          <SocialIcons />
        </div>
      </div>
    </nav>
  );
};
