import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logo = ({ mobile, open, setOpen }) => (
  <Link id="logo" to="/" onClick={() => (mobile && open ? setOpen(false) : '')}>
    Yume Yamada
  </Link>
);

export default Logo;

Logo.propTypes = { mobile: PropTypes.bool, open: PropTypes.bool, setOpen: PropTypes.func };
Logo.defaultProps = { mobile: false, open: false, setOpen: () => {} };
