import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './header/Header';
import '../stylesheets/application.scss';
import Top from './Top';
import About from './About';
import Gallery from './Gallery';
import Work from './work/Work';
import ContentfulProvider from './provider/ContentfulProvider';

const App = () => {
  const location = useLocation();

  return (
    <ContentfulProvider>
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Top />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:slug" element={<Work />} />
        </Routes>
      </AnimatePresence>
    </ContentfulProvider>
  );
};

export default App;
