/* eslint-disable react/no-unknown-property */
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF, Center, useAnimations } from '@react-three/drei';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '../helpers/contentful';
import showHeader from '../helpers/header';

const Scene = () => {
  const loadingManager = new THREE.LoadingManager(
    () => {},
    () => {
      const loadingScreen = document.querySelector('.loading-screen');
      const percentage = document.querySelector('.percentage p');

      if (loadingScreen) {
        loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
          percentage.innerText = `${(itemsLoaded / itemsTotal) * 100}%`;
          if (itemsLoaded === itemsTotal) {
            window.setTimeout(() => {
              loadingScreen.classList.add('loaded');

              showHeader();
            }, 500);
          }
        };
      }
    }
  );
  const textureLoader = new THREE.TextureLoader(loadingManager);

  const { nodes } = useGLTF(
    '/scene.glb',
    { draco: 'https://www.gstatic.com/draco/v1/decoders/' }
  );
  const dancer = useGLTF(
    '/dancer.glb',
    { draco: 'https://www.gstatic.com/draco/v1/decoders/' }
  );
  const rightDancer = useGLTF(
    '/dancer_right.glb',
    { draco: 'https://www.gstatic.com/draco/v1/decoders/' }
  );

  const leftDancerAnimation = useAnimations(dancer.animations, dancer.scene);
  const rightDancerAnimation = useAnimations(rightDancer.animations, rightDancer.scene);

  const bakedTexture = useMemo(() => {
    const texture = textureLoader.load('./texture.jpg');
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;

    return texture;
  });

  const colors = {
    white: '#E7E7E7',
    orange: '#E7C69F',
    lightPink: '#FFD4D8',
    turquoise: '#0A6C75',
    black: '#000000'
  };

  const cloudRefs = useRef([]);
  const canvasGroupRefs = useRef([]);
  const bakedSceneRef = useRef();
  const orbitControlsRef = useRef();
  const [randomValues, setRandomValues] = useState([]);
  const [randomWorks, setRandomWorks] = useState({});
  const [fixedWork, setFixedWork] = useState({});
  const { camera } = useThree();
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  const isMainCanvas = meshName => (
    meshName.includes('canvasFrontside') && meshName === 'canvasFrontside2'
  );
  const isOtherCanvas = meshName => (
    meshName.includes('canvasFrontside') && meshName !== 'canvasFrontside2'
  );

  const material = meshName => {
    if (meshName === 'angelRing') return <meshBasicMaterial color={colors.white} />;
    if (meshName === 'face') return <meshBasicMaterial color={colors.white} />;
    if (meshName.includes('ceilingLight')) return <meshBasicMaterial color={colors.lightPink} />;
    if (meshName.includes('yumeYamadaText')) return <meshBasicMaterial color={colors.turquoise} />;
    if (['galleryText', 'infoText'].includes(meshName)) {
      return <meshBasicMaterial color={colors.black} />;
    }
    if (meshName.includes('spotLight')) return <meshBasicMaterial color={colors.yellow} />;
    if (meshName.includes('windowLight')) return <meshBasicMaterial color={colors.orange} />;
    if (meshName === 'Scene') return null;
    if (isMainCanvas(meshName)) {
      if (fixedWork.thumbnail) {
        const fixedWorkTexture = textureLoader.load(fixedWork.thumbnail);
        fixedWorkTexture.flipY = false;
        fixedWorkTexture.colorSpace = THREE.SRGBColorSpace;
        fixedWorkTexture.magFilter = THREE.NearestFilter;

        return (
          <meshBasicMaterial map={fixedWorkTexture} key={fixedWorkTexture} />
        );
      }
    }
    if (isOtherCanvas(meshName)) {
      if (Object.keys(randomWorks).length > 0) {
        const randomWorkTexture = textureLoader.load(randomWorks[meshName].thumbnail);
        randomWorkTexture.flipY = false;
        randomWorkTexture.colorSpace = THREE.SRGBColorSpace;

        return (
          <meshBasicMaterial map={randomWorkTexture} key={randomWorkTexture} />
        );
      }
    }

    return <meshBasicMaterial map={bakedTexture} />;
  };

  const mesh = (scene, meshName, index) => {
    if (meshName.includes('cloud')) {
      return (
        <mesh
          geometry={scene.geometry}
          ref={el => { cloudRefs.current[index] = el; }}
          key={meshName}
        >
          {material(meshName)}
        </mesh>
      );
    }
    if (meshName === 'bakedScene') {
      return (
        <mesh geometry={scene.geometry} ref={bakedSceneRef} key={meshName}>
          {material(meshName)}
        </mesh>
      );
    }
    if (meshName.includes('canvas')) {
      let workDetailUrl = '';
      if (Object.keys(randomWorks).length > 0) {
        workDetailUrl = meshName === 'canvas2'
          ? fixedWork.url : randomWorks[scene.frontside.name].url;
      }

      return (
        <group
          ref={el => { canvasGroupRefs.current[parseInt(meshName.match(/\d+/g)[0], 10) - 1] = el; }}
          onClick={() => navigate(workDetailUrl)}
          key={meshName}
        >
          <mesh geometry={scene.frontside.geometry}>
            {material(scene.frontside.name)}
          </mesh>
          <mesh geometry={scene.backside.geometry}>
            {material(scene.backside.name)}
          </mesh>
        </group>
      );
    }
    if (/gallery|info/.test(meshName)) {
      return (
        <group
          onClick={() => navigate(meshName === 'gallery' ? '/gallery' : '/about')}
          key={meshName}
        >
          <mesh geometry={scene.text.geometry}>
            {material(scene.text.name)}
          </mesh>
          <mesh geometry={scene.sign.geometry}>
            {material(scene.sign.name)}
          </mesh>
        </group>
      );
    }

    return (
      <mesh geometry={scene.geometry} key={meshName}>
        {material(meshName)}
      </mesh>
    );
  };

  const groupedCanvas = {};

  Object.entries(nodes).forEach(([key, value]) => {
    const match = key.match(/(canvasFrontside|canvasBackside)(\d+)/);
    if (match) {
      const canvasNumber = match[2];
      const newKey = `canvas${canvasNumber}`;

      if (!groupedCanvas[newKey]) {
        groupedCanvas[newKey] = {};
      }

      if (key.includes('Frontside')) {
        groupedCanvas[newKey].frontside = value;
      } else if (key.includes('Backside')) {
        groupedCanvas[newKey].backside = value;
      }
    }
  });

  const groupedSigns = {};

  Object.entries(nodes).forEach(([key, value]) => {
    const match = key.match(/(gallery|info)/);
    if (match) {
      const newKey = key.includes('gallery') ? 'gallery' : 'info';

      if (!groupedSigns[newKey]) {
        groupedSigns[newKey] = {};
      }

      if (key.includes('Text')) {
        groupedSigns[newKey].text = value;
      } else {
        groupedSigns[newKey].sign = value;
      }
    }
  });

  const reconstructedNodes = Object.entries(nodes).reduce((acc, [key, value]) => {
    if (!/canvas|gallery|info/.test(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  Object.entries(groupedCanvas).forEach(([key, value]) => {
    reconstructedNodes[key] = value;
  });
  Object.entries(groupedSigns).forEach(([key, value]) => {
    reconstructedNodes[key] = value;
  });

  useFrame(state => {
    const { elapsedTime } = state.clock;

    if (randomValues.length > 1) {
      cloudRefs.current.concat(canvasGroupRefs.current).filter(Boolean).forEach((cloud, index) => {
        const randomValue = randomValues[index];
        cloud.position.x = Math.cos(elapsedTime * randomValue.x);
        cloud.position.y = Math.sin(elapsedTime * randomValue.y);
        cloud.position.z = Math.cos(elapsedTime * randomValue.z);
      });
    }
  });

  const randomAnimationMultipler = (max, min) => Math.random() * (max - min) + min;

  useEffect(() => {
    setRandomValues(
      cloudRefs.current.concat(canvasGroupRefs.current).map(() => (
        {
          x: randomAnimationMultipler(2.0, -0.09),
          y: randomAnimationMultipler(1.0, 0.07),
          z: randomAnimationMultipler(0.8, 0.09)
        }
      )).filter(Boolean)
    );

    [rightDancerAnimation, leftDancerAnimation].forEach(animation => {
      animation.actions['Armature|mixamo.com|Layer0'].play();
    });

    dancer
      .scene
      .children[0]
      .children[0]
      .material = new THREE.MeshBasicMaterial({ color: colors.white });
    rightDancer
      .scene
      .children[0]
      .children[0]
      .material = new THREE.MeshBasicMaterial({ color: colors.white });

    client
      .getEntries({ content_type: 'work', 'fields.featured': true, limit: 4 }).then(response => {
        const works = response.items;
        const fixedWorkTitle = 'Gas Me at Ease';
        const retrievedFixedWork = works.find(item => item.fields.title === fixedWorkTitle).fields;
        showHeader();
        setFixedWork(
          {
            thumbnail: retrievedFixedWork.thumbnail.fields.file.url,
            url: `/gallery/${retrievedFixedWork.slug}`
          }
        );

        const otherWorks = {
          canvasFrontside1: null,
          canvasFrontside3: null,
          canvasFrontside4: null
        };
        const randomlySelectedWorks = works
          .filter(work => work.fields.title !== fixedWorkTitle)
          .map(work => {
            const { fields } = work;

            return { thumbnail: fields.thumbnail.fields.file.url, url: `/gallery/${fields.slug}` };
          });

        Object.keys(otherWorks).forEach((key, index) => {
          otherWorks[key] = randomlySelectedWorks[index];
        });

        setRandomWorks(otherWorks);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (width <= 928) {
      camera.zoom = 0.7;
      camera.updateProjectionMatrix();
    } else {
      camera.zoom = 1;
      camera.updateProjectionMatrix();
    }
  }, [width, camera]);

  useEffect(() => {
    const updateWidth = () => setWidth(window.innerWidth);

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <Center>
      <OrbitControls
        makeDefault
        ref={orbitControlsRef}
        maxDistance={50}
        minDistance={25}
      />
      { Object.entries(reconstructedNodes).map(([key, value], index) => mesh(value, key, index)) }
      <group>
        <primitive
          object={dancer.scene}
          position={[-8, -2.6, -1]}
          scale={[0.8, 0.8, 0.8]}
        />
        <primitive
          object={rightDancer.scene}
          position={[-8, -2.6, 1]}
          scale={[0.8, 0.8, 0.8]}
          rotation-y={Math.PI}
        />
      </group>
    </Center>
  );
};

export default Scene;
